<template>
  <div>
    <v-row cols="12" class="text-center mb-4">
      <v-col cols="12" class="text-center">
        <h1
          style="color: white; font-family: 'Raleway light', sans-serif"
          class="mb-4"
        >
          {{ $t("features_page.title") }}
        </h1>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="12"
        class="mt-3"
        v-for="(feature, index) of features"
        :key="index"
      >
        <v-card class="border-16" style="padding: 40px" height="100%">
          <v-row class="justify-center align-center ma-0">
            <v-img>
              <img :src="feature.imgSrc" height="100" width="100" />
            </v-img>
          </v-row>
          <v-row class="justify-center mb-4">
            <v-col>
              <h3
                style="
                  margin-bottom: 20px;
                  color: black;
                  font-family: 'Raleway light', sans-serif;
                "
              >
                {{ $t(feature.title) }}
              </h3>
              <span>
                {{ $t(feature.description) }}
              </span>
            </v-col>
          </v-row>
          <v-divider
            style="
              width: 120px;
              margin-left: 30% !important;
              margin-bottom: 20px;
            "
          ></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <Footer bg-color="#b9b9b9"/>
  </div>
</template>
<script>
import keywordGeneration from "../../assets/img/sem.png";
import addCopyIntegration from "../../assets/img/boost.png";
import campaignSetup from "../../assets/img/duplicate.png";
import analytics from "../../assets/img/analysis.png";

export default {
  name: "Features",
  components: {
    Footer: () => import("@/components/Footer")
  },
  data() {
    return {
      features: [
        {
          title: "features_page.keyword_generation_card_title",
          description: "features_page.keyword_generation_card_description",
          imgSrc: keywordGeneration,
        },
        {
          title: "features_page.adcopy_generation_card_title",
          description: "features_page.adcopy_generation_card_description",
          imgSrc: addCopyIntegration,
        },
        {
          title: "features_page.campaign_setup_card_title",
          description: "features_page.campaign_setup_card_description",
          imgSrc: campaignSetup,
        },
        {
          title: "features_page.analytics_card_title",
          description: "features_page.analytics_card_description",
          imgSrc: analytics,
        },
      ],
    };
  },
};
</script>
